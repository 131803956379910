/** @jsx jsx */
import {useRef, useState} from 'react'
import { jsx, Link as UILink, useThemeUI } from "theme-ui";
import { useResponsiveValue } from '@theme-ui/match-media'
import { Link as GatsbyLink } from 'gatsby'
import { gsap } from "gsap/all"
import { getRandomSvg } from './../helpers/getRandomSvg'

export default ({blank, children, ...props}) => {
  const ref = useRef()
  const {colorMode} = useThemeUI()
  const [isActive, setIsActive] = useState(false)
  const Link = props.to ? GatsbyLink : UILink
  const line = useResponsiveValue([1.5, 2, 2, 3])
  const colours = ['hover1', 'hover2', 'hover3', 'hover4', 'hover5']

  function linkLeave () {
    if (!isActive) return

    const link = this.current
    link.querySelector('.hand').remove(); // возвращаем содержание элемента
    setIsActive(false)
  }

  function linkHover () {
    if (isActive) return
    setIsActive(true)

    const link = this.current
  
    const types = ['lines','curve','ellipse']
      let type = types[Math.floor(Math.random() * types.length)]
      const currentSvg = getRandomSvg(type, link.offsetWidth, link.offsetHeight)

      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      svg.classList.add('hand')
      svg.setAttribute('viewBox', '0 0 ' + currentSvg.width + ' ' + currentSvg.height);
      svg.style.zIndex = '10';
      if (type === 'lines' || type === 'curve') {
        svg.style.height = currentSvg.height + 'px';
        svg.style.bottom = -currentSvg.height/2 + 'px';
        svg.style.width = '100%';
      } else {
        // svg.style.height = 'auto';
        svg.style.height = currentSvg.height + 'px';
        svg.style.width = '100%';
        svg.style.top = '50%';
        svg.style.transform = 'translateY(-50%)';
      }

      // const fontSize = (typeof window !== 'undefined') ?parseInt(window.getComputedStyle(link, null).getPropertyValue("font-size"), 10) : null
      // let strokeWidth // = fontSize >= 44 ? 4 : 
      
      // if (fontSize >= 44) {
      //     strokeWidth = 3
      // } else if (fontSize >= 24) {
      //     strokeWidth = 2
      // } else {
      //     strokeWidth = 1
      // }

      currentSvg.path.forEach((path, i) => {
        const svgPath = document.createElementNS('http://www.w3.org/2000/svg', 'path')
        svgPath.setAttribute('d', path)
        const totalLength = svgPath.getTotalLength()
        // TweenLite.set(svgPath, { 'stroke-width': strokeWidth });
        gsap.set(svgPath, { 'stroke-dasharray': totalLength });
        gsap.fromTo(svgPath, .5, { 'stroke-dashoffset': totalLength }, { 'stroke-dashoffset': 0, ease: "power3.out" })
        svg.appendChild(svgPath);
      });
      link.appendChild(svg);
  }

  return (<Link
    {...props}
    activeClassName='active'
    ref={ref}
    target={blank && '_blank'}
    rel={blank && 'noreferrer'}
    onFocus={linkHover.bind(ref)} onBlur={linkLeave.bind(ref)} onMouseEnter={linkHover.bind(ref)} onMouseLeave={linkLeave.bind(ref)}
    sx={{
      color: 'inherit',
      whiteSpace: 'nowrap',
      display: 'inline-block',
      textDecoration: 'none',
      outline: 'none',
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        display: 'inline-block',
        bg: 'muted',
        left: '50%',
        height: '4px',
        bottom: 0,
        borderRadius: 2,
        opacity: .2,
        transform: 'translateY(2px) translateX(-50%)',
        zIndex: -1,
        width: '100%',
      },
      '&:hover, &:focus': {
        color: 'vibrantText',
        '&:after': {
          opacity: 0,
        },
      },
      ...props.sx,
      'svg.hand': {
        position: 'absolute',
        opacity: colorMode === 'light' ? .8 : .95,
        left: 0,
        bottom: 0,
        pointerEvents: 'none',
        'path': {
          fill: 'none',
          stroke: colours[Math.floor(Math.random() * colours.length)],
          strokeWidth: line,
        },
      },
    }}
  >
    {children}
  </Link>)}
