const svgs = require('./svgs.json')

function distance(a, b) { // [el_width, el_height], [svg_width, svg_height]
    var distance = 0
    var dimensions = Math.max(a.length, b.length)
    // console.log('dimensions: ', dimensions)
    for (var i = 0; i < dimensions; i++) {
      distance += Math.abs((b[i] || 0) - (a[i] || 0))
    }
    return distance
  }
  
  
  export function getRandomSvg(type, width, height) {
    const currentHover = svgs.find(hover => hover.type === type);
    
    let currentSvgArray = [];    
    
    currentHover.svg.forEach(hover => {
      // выберем наибольшую свг из тех что меньше по ширине
      if (type === 'ellipse') {
        if (width <= hover.width && height <= hover.height) {
          currentSvgArray.push(hover);
        }
      } else {
        if (width <= hover.width) {
          currentSvgArray.push(hover);
        }
      }
    });

    const coords = [width, height]

    return currentSvgArray
      .map(hover => {
        return {
          ...hover,
          distance: distance(coords, [hover.width, hover.height]),
        } 
      })
      .sort((a, b) => a.distance - b.distance)[0]
}