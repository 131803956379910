/** @jsx jsx */
import { jsx, Link as UILink } from "theme-ui";
import { Link as GatsbyLink } from 'gatsby'

export default ({children, blank,...props}) => {
  const Link = props.to ? GatsbyLink : UILink

  return (<Link
    {...props}
    activeClassName='active'
    target={blank && '_blank'}
    rel={blank && 'noreferrer'}
    sx={{...props.sx}}
  >
    {children}
  </Link>)}

const ExtIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M17.99 2.37L16.89 1.27C14.97 2.88 12.6 2.9 10.51 1L9.48 2.6C10.27 3.25 11.16 3.63 12.17 3.79C13.15 3.91 13.99 3.79 14.71 3.42C12.99 5.15 12.5 5.68 9.9 8.51C9.64 8.79 9.4 9.07 9.18 9.35C8.1 9.14 7.01 9.27 5.98 9.63C3.94 10.35 2.14 11.98 1 13.77L2.35 14.63C3.35 13.07 4.88 11.71 6.51 11.13C7.07 10.93 7.64 10.83 8.2 10.84C6.9 13.25 6.96 15.54 7.84 17.17C8.41 18.23 9.34 19.01 10.42 19.26C11.54 19.52 12.71 19.19 13.66 18.23C14.61 17.27 15.47 14.25 12.35 11.11C11.84 10.6 11.31 10.2 10.77 9.91C10.86 9.8 10.96 9.69 11.06 9.58C12.74 7.76 13.53 6.89 14.34 6.05C14.79 5.59 15.24 5.13 15.85 4.52C15.47 5.25 15.34 6.1 15.48 7.1C15.64 8.1 16.02 9 16.65 9.77L18.26 8.75C16.38 6.67 16.38 4.3 17.99 2.37ZM11.23 12.25C13.82 14.86 12.78 16.87 12.54 17.12C11.95 17.72 11.33 17.84 10.79 17.72C10.23 17.59 9.65 17.16 9.26 16.43C8.65 15.29 8.51 13.41 9.83 11.24C10.3 11.46 10.77 11.79 11.23 12.25Z" />
  </svg>
)

const AppStore = props => (
  <svg width="24" height="24" aria-hidden viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14.1929 3.17578C14.9664 2.34375 15.3882 1.17188 15.3179 0C14.2632 0 13.0328 0.621094 12.2711 1.52344C11.6617 2.28516 11.0171 3.45703 11.2515 4.64062C12.3414 4.76953 13.5132 4.07812 14.1929 3.17578ZM15.2242 4.89844C13.5836 4.89844 12.1304 5.80078 11.3687 5.80078C10.5484 5.80078 9.37652 4.85156 8.0523 4.89844C6.32965 4.93359 4.74761 5.95312 3.84527 7.45312C3.22418 8.53125 2.97808 9.79688 3.00152 11.0859C3.03668 13.5234 3.98589 16.0547 5.11089 17.6719C5.97808 18.9023 6.95074 20.2266 8.33355 20.2266C9.57574 20.2266 10.0445 19.3945 11.6617 19.3945C13.1734 19.3945 13.6539 20.2266 14.9664 20.2266C16.3375 20.2266 17.2515 18.9727 18.0718 17.7305C19.1031 16.2773 19.4429 14.8945 19.4898 14.8477C19.4429 14.8477 16.8414 13.793 16.7476 10.7812C16.7476 8.14453 18.8921 6.96094 18.9742 6.90234C17.814 5.09766 15.9273 4.89844 15.2242 4.89844Z" />
  </svg>
)

const GooglePlay = props => (
  <svg width="24" height="24" aria-hidden viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6.47861 2.31985C5.69615 1.88738 4.77746 1.89738 4 2.3261L13.112 10.7319L16.1731 7.6708L6.47861 2.31985Z" />
    <path d="M3.34998 3C3.12749 3.36373 3 3.77996 3 4.22118V19.4028C3 19.8303 3.11624 20.2378 3.32623 20.594L12.4895 11.4308L3.34998 3Z"/>
    <path d="M20.2009 9.61116L17.2811 8L14 11.2798L18.021 14.9884L20.2021 13.7847C20.9871 13.3497 21.4571 12.5697 21.4571 11.6973C21.4558 10.8248 20.9871 10.0449 20.2009 9.61116Z" />
    <path d="M13.1882 12L4 21.1882C4.39873 21.4157 4.8362 21.5357 5.27618 21.5357C5.70115 21.5357 6.12863 21.4282 6.51986 21.212L16.9405 15.4611L13.1882 12Z" />
  </svg>
)

const Anchor = props => (
  <svg width="24" height="24" aria-hidden viewBox="0 0 24 24" version="1.1" role="img" {...props}>
    <path d="M13.6161105,7.92940972 C14.1717932,8.07802226 14.829351,8.45884189 15.172022,8.80250838 C17.5429348,11.180309 17.5521962,14.5705325 15.1812834,16.9483331 L15.1812834,16.9483331 L11.9120169,20.2178089 C9.54110412,22.5956095 6.1699625,22.5956095 3.78978834,20.2085206 C1.41887555,17.83072 1.39109142,14.4219199 3.78052696,12.0719842 L3.78052696,12.0719842 L6.04030321,9.82421957 C5.6235412,10.9945433 5.85507565,12.3506327 6.28109904,13.344479 L6.28109904,13.344479 L5.73467773,13.8924878 C4.45660756,15.1742709 4.46586894,16.9483331 5.76246187,18.2486928 C7.04053204,19.5304759 8.80945525,19.5397642 10.0967868,18.2579811 L10.0967868,18.2579811 L13.2271326,15.1278295 C14.5052028,13.8367581 14.4959414,12.0626959 13.2086098,10.7716245 C12.8288933,10.3908049 12.1157672,10.0842915 11.5415618,9.98212039 L11.5415618,9.98212039 Z M12.0879831,3.77754697 C14.4588959,1.40903466 17.8300375,1.39974637 20.2102117,3.79612353 C22.5811244,6.17392413 22.6089086,9.5827242 20.219473,11.9326599 L20.219473,11.9326599 L17.9596968,14.1711363 C18.3764588,13.0101008 18.1449244,11.6540114 17.7096396,10.6601651 L17.7096396,10.6601651 L18.2560609,10.1121564 C19.5433924,8.83037323 19.5341311,7.05631107 18.2375381,5.75595137 C16.959468,4.47416823 15.1905447,4.46487995 13.9032132,5.74666308 L13.9032132,5.74666308 L10.7728674,8.87681465 C9.48553585,10.1678861 9.50405861,11.9419482 10.7913902,13.2330196 C11.1711067,13.6138393 11.8842328,13.9203526 12.4584382,14.0132355 L12.4584382,14.0132355 L10.3838895,16.0752344 C9.82820684,15.9266219 9.170649,15.5458023 8.82797801,15.2021358 C6.44780385,12.8243352 6.44780385,9.43411166 8.80945525,7.05631107 L8.80945525,7.05631107 Z"></path>
  </svg>
)

export {AppStore, GooglePlay, Anchor, ExtIcon}